import styles from './Privacy.module.scss';

const Privacy = () => {
  return (
    <div className={styles.holder}>
      <div className={styles.content}>
        <p className={styles.title}>Privacy Policy</p>
        <p className={styles.paragraph}>Last Updated: 23. October 2024</p>
        <p className={styles.paragraph}>
          This Privacy Policy describes how Rizzzler ("we", "us", or "our")
          collects, uses, and shares your personal information when you use our
          application. By accessing or using the Rizzzler app, you agree to the
          terms of this Privacy Policy.
        </p>

        <p className={styles.title}>1. Information We Collect</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We collect information you provide directly to us when
          you create an account, update your profile, or interact with other
          users on Rizzzler.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}This includes your name, email address, photos, and any
          other information you choose to provide.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We also collect data about your usage of the app, such as
          interactions with other users, preferences, and in-app purchases.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}If you choose to allow access, we may collect location
          information to provide location-based services.
        </p>

        <p className={styles.title}>2. How We Use Your Information</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We use the information we collect to operate, maintain,
          and improve the Rizzzler app, including providing you with a
          personalized experience and relevant matches.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We use your email address to communicate with you about
          your account and to send promotional updates, subject to your
          preferences.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We use your information to prevent fraud and enhance
          security within the app.
        </p>

        <p className={styles.title}>3. Sharing of Your Information</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We do not sell or rent your personal information to third
          parties. However, we may share your information with service providers
          who assist us in providing the app.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We may share information if required by law, such as in
          response to a court order or subpoena, or if we believe it is
          necessary to prevent fraud, protect safety, or enforce our Terms of
          Service.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}In the event of a merger, acquisition, or sale of all or
          a portion of our assets, your personal information may be transferred
          as part of the transaction.
        </p>

        <p className={styles.title}>4. Your Choices</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}You can update your profile and communication preferences
          at any time in the app settings.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}You can disable location access in your device settings,
          although this may limit certain features of the app.
        </p>
        <p className={styles.paragraph}>
          {'\u25CF   '}You can delete your account at any time by contacting us
          at support@rizzzler.com or doing so in the app (Profile{'>'}Manage
          Account)
        </p>

        <p className={styles.title}>5. Security</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We take reasonable measures to protect your information
          from unauthorized access or disclosure. However, no security measures
          are foolproof, and we cannot guarantee the absolute security of your
          information.
        </p>

        <p className={styles.title}>6. Children's Privacy</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}Rizzzler is not intended for use by individuals under the
          age of 18. We do not knowingly collect personal information from
          children under 18. If we become aware that we have collected such
          information, we will take steps to delete it.
        </p>

        <p className={styles.title}>7. Third-Party Links</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}Rizzzler may contain links to third-party websites or
          services. We are not responsible for the privacy practices or the
          content of those third-party sites.
        </p>

        <p className={styles.title}>8. Changes to This Privacy Policy</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}We may update this Privacy Policy from time to time to
          reflect changes to our practices or for other operational, legal, or
          regulatory reasons. We will notify you of any significant changes by
          posting a notice in the app or by email.
        </p>

        <p className={styles.title}>9. Your Rights</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}Depending on your location, you may have rights regarding
          your personal information, such as the right to access, correct, or
          delete the data we have about you. To exercise these rights, please
          contact us at support@rizzzler.com.
        </p>

        <p className={styles.title}>10. Contact Us</p>
        <p className={styles.paragraph}>
          {'\u25CF   '}If you have any questions about this Privacy Policy or
          our practices, please contact us at support@rizzzler.com.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
