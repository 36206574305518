import styles from './Support.module.scss';

const SupportPage = () => {
  return (
    <div className={styles.holder}>
      <h3>Contact us at:</h3>{' '}
      <a className={styles.email} href="mailto:support@rizzzler.com">
        support@rizzzler.com
      </a>
    </div>
  );
};

export default SupportPage;
