import { lazy, Suspense, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom';

import Loading from './components/Loading/Loading';
import SupportPage from 'pages/Support/Support';
import Privacy from 'pages/Privacy/Privacy';

const Router = () => {
  const [loading, setLoading] = useState(true);

  const VerifyEmailPage = lazy(() => import('./pages/VerifyEmail/VerifyEmail'));
  const ResetPasswordPage = lazy(
    () => import('./pages/ResetPassword/ResetPassword')
  );
  const HomePage = lazy(() => import('./pages/Home/Home'));
  // const ForgotPasswordPage = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
  // const ResetPasswordPage = lazy(() => import('./pages/ForgotPassword/ResetPassword/ResetPassword'));

  // const FinishProfile = lazy(() => import('./pages/FinishProfile/FinishProfile'));

  return (
    <Suspense fallback={<Loading show={true} />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/verify" element={<VerifyEmailPage />} />
        <Route path="/passwordChange" element={<ResetPasswordPage />} />
        <Route path="/privacy" element={<Privacy />} />

        {/* <Route path="/finish-profile" element={<FinishProfile />} />
        <Route path="forgotPassword">
          <Route index element={<ForgotPasswordPage />} />
        </Route>
        <Route path="/reset-password" element={<ResetPasswordPage />} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
